import AppContent from './AppContent'
import { BrowserRouter as Router } from 'react-router-dom'

const App = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    )
}

export default App
