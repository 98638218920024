import { Route, Switch } from 'react-router-dom'

import { DEFAULT_ROUTES } from './Routes'
import NavBar from 'components/common/navbar/NavBar'
import Style from './AppContent.module.scss'

const AppContent = () => {
    const getRoutes = () =>
        DEFAULT_ROUTES.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path}>
                {route.component}
            </Route>
        ))

    return (
        <div className={Style.siteContainer}>
            {/* <NavBar /> */}
            <div className={Style.appContent}>
                <Switch>{getRoutes()}</Switch>
            </div>
        </div>
    )
}

export default AppContent
