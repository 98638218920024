import * as brands from './brands'
import * as regular from './regular'
import * as solid from './solid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from './icons'
import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
    ...Object.values(brands),
    ...Object.values(regular),
    ...Object.values(solid)
)

export const Icon = ({ icon, mask, ...props }) => {
    icon = Icons[icon]

    return icon ? (
        <FontAwesomeIcon icon={icon} mask={mask && Icons[mask]} {...props} />
    ) : (
        ''
    )
}

Icon.propTypes = {
    ...FontAwesomeIcon.FontAwesomeIconProps,
}
