import HomePage from 'components/homepage/HomePage'
import Profile from 'components/profile/Profile'

export const DEFAULT_ROUTES = [
    {
        path: '/',
        exact: true,
        component: <HomePage />,
    },
    {
        path: '/profile',
        component: <Profile />,
    },
]
