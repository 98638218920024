import { Container } from 'reactstrap'
import { SocialIcon } from 'react-social-icons'
import Style from './HomePage.module.scss'

const HomePage = () => {
    const tag = `</>`
    const socialLinks = [
        { url: 'https://twitter.com/_renzsanchez' },
        { url: 'https://www.linkedin.com/in/renz-sanchez/' },
        { url: 'https://www.instagram.com/renz.sanchez/' },
        { url: 'mailto:edmundterencesanchez@gmail.com', network: 'email' },
    ]

    return (
        <Container fluid className={Style.container}>
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <span className={Style.title}>Renz Sanchez</span>
                <code className={Style.tag}>{tag}</code>
                <div className="d-flex flex-row mt-5">
                    {socialLinks.map((link, index) => (
                        <SocialIcon
                            key={index}
                            id={index}
                            className={Style.social}
                            url={link.url}
                            network={link.network}
                            style={{ width: 35, height: 35 }}
                            fgColor="#fff"
                            target="_blank"
                        />
                    ))}
                </div>
            </div>
        </Container>
    )
}

export default HomePage
